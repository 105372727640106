import React from 'react';
import leaf from '../../../assets/leaf.png';
import Reveal from '../../../components/Reveal/Reveal';

const ServiceBanner = () => {
    return (
        <div className='mx-[5%] my-9 text-white'>
            <Reveal>
            <div className='flex items-center'>
            <h1 className='text-4xl lg:text-9xl font-bold'>Digital Services </h1>
            <img className='hidden lg:block' src={leaf} alt="" />
            </div>
            <h1 className='text-4xl lg:text-9xl font-bold'>For Web Design and Development</h1>
            </Reveal>
            
        </div>
    );
};

export default ServiceBanner;