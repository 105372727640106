import React from 'react';
import Reveal from '../../../components/Reveal/Reveal';

const Faq = () => {
    return (
        <div className='my-20'>
            <div>
                <Reveal>
                <div className='text-center'>
                    <div className='flex items-center gap-3 justify-center'>
                        <svg width="38" height="12" viewBox="0 0 38 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 11.5H36.5L28 1" stroke="#696969" />
                        </svg>
                        <h4 className="text-xl text-green-400">Asked Questions</h4>
                    </div>
                    <h1 className="text-5xl font-semibold my-5 text-white">Frequently Asked Questions</h1>
                </div>
                </Reveal>
                <Reveal>
                <div className='w-[60%] mx-auto mt-10'>
                    <div className="collapse collapse-arrow ">
                        <input type="radio" name="my-accordion-2" defaultChecked />
                        <div className="collapse-title text-2xl font-semibold text-white border-b border-slate-600">
                        How does the development process work at HBR Software Solutions?
                        </div>
                        <div className="collapse-content my-5">
                            <p>Our development process begins with a thorough understanding of your requirements and objectives. We then proceed to design, development, testing, and deployment phases, ensuring transparency and collaboration at every step. Our agile approach allows for flexibility and adaptability throughout the project lifecycle.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-2xl font-semibold text-white border-b border-slate-600">
                        What sets HBR Software Solutions apart from other software development companies?
                        </div>
                        <div className="collapse-content my-5">
                        <p>At HBR Software Solutions, we distinguish ourselves through our unwavering commitment to quality, innovation, and client satisfaction. With a team of skilled professionals and a proven track record of successful projects, we deliver results that exceed expectations, time and again.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-2xl font-semibold text-white border-b border-slate-600">
                        How does HBR Software Solutions ensure the security of our projects and data?
                        </div>
                        <div className="collapse-content my-5">
                        <p>Security is our top priority at HBR Software Solutions. We employ industry-leading practices and technologies to safeguard your projects and data against threats and vulnerabilities. From secure coding practices to data encryption, we take every precaution to ensure the confidentiality, integrity, and availability of your information.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-2xl font-semibold text-white border-b border-slate-600">
                        What kind of support does HBR Software Solutions provide after the project is completed?
                        </div>
                        <div className="collapse-content my-5">
                        <p>Our commitment to our clients extends beyond project completion. We offer comprehensive post-launch support and maintenance services to address any issues, implement updates, and ensure the continued success of your software solution. Our dedicated support team is always available to assist you whenever you need us.</p>
                        </div>
                    </div>
                </div>
                </Reveal>
            </div>
        </div>
    );
};

export default Faq;