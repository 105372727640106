import React from 'react';
import { HiOutlineCheckCircle } from "react-icons/hi";
import TitleSlide from '../../../components/TitleSlide/TitleSlide';
import Reveal from '../../../components/Reveal/Reveal';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../components/RevealRight/RevealRight';

const Offer = () => {
    return (
        <div className='mt-9 mb-14'>
            <Reveal>
            <div className='text-center'>
                <h3 className='font-semibold text-green-400 text-xl mb-3'>What We Offer</h3>
                <div className="mx-auto w-[140px]"><TitleSlide width={`w-full`}/></div>
                <h1 className='text-[20px] before:lg:text-[48px] font-bold lg:w-[700px] leading-tight text-center mx-auto tracking-wider text-white'>Collaborate with us you'll get top class services</h1>
            </div>
            </Reveal>
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-3  gap-6 mx-[5%] shadow-white relative">
                <RevealLeft>
                <div className='bg-green-400 text-black px-8 py-14'>
                    <h3 className='text-2xl font-bold tracking-wider'>Building Software</h3>
                    <div className='mt-5 ml-3'>
                        <p className='flex items-center gap-1 text-white'><HiOutlineCheckCircle/> <span className='text-white'>Building great products</span></p>
                        <p className='flex items-center gap-1 my-2 text-white'><HiOutlineCheckCircle/>Make them easy to understand</p>
                        <p className='flex items-center gap-1 text-white'><HiOutlineCheckCircle/>Create difference</p>
                    </div>
                </div>
                </RevealLeft>
                <Reveal>
                <div className='bg-green-400 text-black px-4 py-14'>
                    <h3 className='text-2xl font-bold tracking-wider'>Developing Process</h3>
                    <div className='mt-5 ml-5'>
                        <p className='flex items-center gap-1 text-white'><HiOutlineCheckCircle/> <span className='text-white'>With smart team</span></p>
                        <p className='flex items-center gap-1 text-white my-2'><HiOutlineCheckCircle/> Flexible Pricing</p>
                        <p className='flex items-center gap-1 text-white'><HiOutlineCheckCircle/> Generating Ideas</p>
                    </div>
                </div>
                </Reveal>
                <RevealRight>
                <div className='bg-green-400 text-black px-4 py-14'>
                    <h3 className='text-2xl font-bold tracking-wider'>Client Supporting</h3>
                    <div className='mt-5 ml-5'>
                        <p className='flex items-center gap-1 text-white'><HiOutlineCheckCircle/>Generous to the core</p>
                        <p className='flex items-center gap-1 my-2 text-white'><HiOutlineCheckCircle/>Easygoing full process</p>
                        <p className='flex items-center gap-1 text-white'><HiOutlineCheckCircle/>Celebrate your victories</p>
                    </div>
                </div>
                </RevealRight>
                <div className='animate-pulse'>
                <div className="bg-left"></div>
                </div>
            </div>
           
        </div>
    );
};

export default Offer;