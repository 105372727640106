import React from 'react';
import leaf from '../../../assets/leaf.png';
import WhyUs from '../../Home/WhyUs/WhyUs';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../components/RevealRight/RevealRight';
import Reveal from '../../../components/Reveal/Reveal';

const WebsiteUpgrade = () => {
    return (
        <div className='mx-[5%]'>
            <div className='flex gap-2 my-20'>
                <RevealLeft><h1 className='text-3xl lg:text-6xl font-bold text-white'>Website Upgrade</h1></RevealLeft>
                <RevealRight><img className='w-16 -mt-10' src={leaf} alt="" /></RevealRight>
            </div>
            <div className='my-20'>
                <RevealRight><h1 className='text-3xl text-end font-bold text-white'>Why Upgrade Your Website</h1></RevealRight>
                <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <RevealLeft>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Enhanced User Experience</h1>
                            <p className='text-justify'>A modern, user-friendly interface ensures seamless navigation, reducing bounce rates and increasing engagement.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Improved Performance</h1>
                            <p className='text-justify'>In today's digital landscape, it's crucial to have a website that looks and performs flawlessly across all devices. With Figma, we can create responsive designs that adapt effortlessly to various screen sizes, ensuring that your website looks stunning whether it's viewed on a desktop, tablet, or smartphone.</p>
                        </div>
                    </RevealRight>
                    <RevealLeft>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Stay Competitive</h1>
                            <p className='text-justify'>In a crowded online marketplace, a visually appealing and technically robust website sets you apart from the competition, instilling trust and credibility among your audience.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Adaptability</h1>
                            <p className='text-justify'>With rapid advancements in technology, staying adaptable is key. Upgrading your website ensures compatibility with the latest browsers, devices, and web standards.</p>
                        </div>
                    </RevealRight>
                </div>
            </div>
            <div className="my-20">
                <WhyUs />
            </div>
            <div className='my-20'>
                <Reveal>
                    <h1 className='text-xl lg:text-2xl font-bold text-green-400'>Our Approach to Website Upgrades</h1>
                </Reveal>
                <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <RevealLeft>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Comprehensive Evaluation</h1>
                            <p className='text-justify'>We start by conducting a thorough assessment of your current website, identifying pain points, areas for improvement, and opportunities for growth.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Strategic Planning</h1>
                            <p className='text-justify'> Based on our evaluation and your input, we develop a customized upgrade strategy tailored to your brand identity, target audience, and business objectives.</p>
                        </div>
                    </RevealRight>
                    <RevealLeft>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Design Enhancement</h1>
                            <p className='text-justify'>Our team of experienced designers crafts visually stunning, modern designs that not only captivate your audience but also reflect your brand's personality and values.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Technical Optimization</h1>
                            <p className='text-justify'>From backend improvements to frontend enhancements, we optimize every aspect of your website for performance, speed, and responsiveness.</p>
                        </div>
                    </RevealRight>
                    <RevealLeft>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Content Refinement</h1>
                            <p className='text-justify'>Compelling content is at the heart of every successful website. We refine and optimize your content to ensure it resonates with your audience and drives meaningful interactions.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Testing and Launch</h1>
                            <p className='text-justify'>Before launch, we rigorously test every element of your upgraded website to ensure functionality, compatibility, and performance across various platforms and devices.</p>
                        </div>
                    </RevealRight>
                    <Reveal>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Post-launch Support</h1>
                            <p className='text-justify'>Our commitment doesn't end at launch. We provide ongoing support, maintenance, and analytics tracking to ensure your website continues to deliver exceptional results long after the upgrade.</p>
                        </div>
                    </Reveal>
                </div>
            </div>
        </div>
    );
};

export default WebsiteUpgrade;