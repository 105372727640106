import React from 'react';
import StrategyCard from './StrategyCard';

const ServiceStrategy = () => {
    const strategyes = [
        {
            id: 1,
            steps: '01',
            title: 'Idea Generate',
            des: 'Do initial meetings'
        },
        {
            id: 2,
            steps: '02',
            title: 'Plan & Design',
            des: 'Based on the requirements make the plan & Design'
        },
        {
            id: 3,
            steps: '03',
            title: 'Project Testing',
            des: 'After development process goes for testing'
        },
        {
            id: 4,
            steps: '04',
            title: 'Got Results',
            des: 'Successfully pass the testing ready to go live'
        },
    ]
    return (
        <div className='mx-[8%] my-36'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 mt-36'>
                {
                    strategyes.map(item => <StrategyCard 
                        key={item.id}
                        item={item}
                    />)
                }
            </div>
        </div>
    );
};

export default ServiceStrategy;