import React from 'react';
import SingleServiceCard from './SingleServiceCard';

const ServiceCards = () => {
    const services = [
        {
            id: 1,
            number: '01',
            title: 'Web Design with Figma',
            info: 'Get Creativity, Personalization, An Enhanced User Experience, Increased Conversions, And Results You Can Measure.',
            link: '/design-with-figma'
        },
        {
            id: 2,
            number: '02',
            title: 'Website Development',
            info: 'We Use The Latest In Technology To Develop Websites That Focus On Increasing User Engagement.',
            link: '/web-development'
        },
        {
            id: 3,
            number: '03',
            title: 'Website Maintenance',
            info: 'We Help Businesses By Offering Highly Reliable Managed Website Care',
            link: '/website-maintenance'
        },
        {
            id: 4,
            number: '04',
            title: 'Website Upgrade',
            info: "Enhance your website's performance and aesthetics with our comprehensive upgrade service.",
            link: '/website-upgrade'
        },
    ]
    return (
        <div className='mx-[5%] my-20 relative'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
                {
                    services.map(item => <SingleServiceCard 
                        key={item.id}
                        item={item}
                    />)
                }
            </div>
            <div className='animate-pulse'>
                <div className="bg-middle"></div>
                </div>
        </div>
    );
};

export default ServiceCards;