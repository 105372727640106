import React from 'react';
import leaf from '../../../assets/leaf.png';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import Reveal from '../../../components/Reveal/Reveal';

const Establishment = () => {
    return (
        <div className='my-9 mx-[5%]'>
            <Reveal>
            <div className='lg:flex items-center justify-between'>
                <RevealLeft>
                <div>
                    <p className='lg:w-2/3'>We're <span className='text-green-400'>HBR Software Solution</span>, the independent <span className='text-white'>Web Development Agency</span> behind a family of products that make work simpler, kinder, faster and better.</p>
                </div>
                </RevealLeft>
                <div className='flex items-center'>
                    <h1 className='title-color text-[50px] lg:text-[160px] font-bold'>2023</h1>
                    <img className='w-6 lg:w-20 -ml-14 -rotate-45' src={leaf} alt="" />
                </div>
            </div>
            </Reveal>
        </div>
    );
};

export default Establishment;