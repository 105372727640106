import React from 'react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Reveal from '../../../components/Reveal/Reveal';

const SingleServiceCard = ({ item }) => {
    const { number, title, info, link } = item;
    return (
        <div className='group z-50'>
            <Reveal>
            <div className='flex justify-between items-center'>
                <div className='text-7xl text-slate-900 group-hover:text-white duration-700'>{number}</div>
                <div className='border border-[#ffffff1a] rounded-full p-4 cursor-pointer  bg-[#ffffff1a] text-green-400 group-hover:text-black group-hover:bg-green-400 duration-700'>
                    <Link to={link}><IoIosArrowRoundForward className='font-bold text-6xl -rotate-45 flex' /></Link>
                </div>
            </div>
            <div>
                <h4 className='text-xl font-semibold text-white my-5 group-hover:underline duration-700'>{title}</h4>
                <p className='group-hover:text-white'>{info}</p>
            </div>
            <div className='h-1 w-full bg-slate-900 rounded mt-10 group-hover:bg-green-400 duration-700'></div>
            </Reveal>
        </div>
    );
};

export default SingleServiceCard;