import React from 'react';
import leaf from '../../../assets/leaf.png';
import WhyUs from '../../Home/WhyUs/WhyUs';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../components/RevealRight/RevealRight';
import Reveal from '../../../components/Reveal/Reveal';

const WebsiteMaintenance = () => {
    return (
        <div className='mx-[5%]'>
            <div className='my-20'>
                <div className='flex gap-2'>
                    <RevealLeft>
                        <h1 className='text-3xl lg:text-6xl font-bold text-white'>Website Maintenance</h1>
                    </RevealLeft>
                    <RevealRight><img className='w-16 -mt-10' src={leaf} alt="" /></RevealRight>
                </div>
                <div className='mt-4'>
                    <Reveal><p className='lg:w-3/4'>Your website is the digital face of your business, often forming the first impression potential customers have of your brand. A well maintained website not only enhances user experience but also plays a pivotal role in improving search engine rankings, boosting traffic, and ultimately driving conversions.</p></Reveal>
                </div>
            </div>
            <div className='my-20'>
                <Reveal><h1 className='text-3xl lg:text-5xl font-bold text-white text-center'>Our Website Maintenance Services</h1></Reveal>
                <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <RevealLeft>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Content Updates</h1>
                            <p className='text-justify'>Keeping your website content fresh and up-to-date is essential for engaging your audience and conveying your brand message effectively. From text edits to image replacements, we ensure your content reflects the latest developments in your business.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Security Enhancements</h1>
                            <p className='text-justify'>Protecting your website from potential threats is paramount in today's digital landscape. Our team implements robust security measures, including regular software updates, malware scanning, and firewall installation, to safeguard your website against cyber attacks.</p>
                        </div>
                    </RevealRight>
                    <RevealLeft>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Bug Fixes and Troubleshooting</h1>
                            <p className='text-justify'>Even the most well-designed websites may encounter technical glitches from time to time. Our experts conduct thorough diagnostics to identify and resolve any issues promptly, minimizing downtime and preventing disruptions to your online operations.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Backup and Recovery</h1>
                            <p className='text-justify'>Data loss can be catastrophic for any business. We implement regular backups of your website files and databases, allowing for swift recovery in the event of unforeseen circumstances such as server crashes or malware attacks.</p>
                        </div>
                    </RevealRight>
                </div>
            </div>
            <div className="my-20">
                <WhyUs />
            </div>
            <div className='my-20'>
                <RevealLeft><h1 className='text-xl lg:text-2xl font-bold text-green-400'>Why Choose Us for Your Website Maintenance Needs</h1></RevealLeft>
                <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <RevealLeft>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Personalized Approach</h1>
                            <p className='text-justify'>We understand that every business is unique, which is why we tailor our website maintenance services to align with your specific goals and requirements.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Dedicated Support</h1>
                            <p className='text-justify'>Our team of professionals is committed to providing unparalleled support and assistance whenever you need it. Whether you have a question or require urgent assistance, we're here to help you every step of the way.</p>
                        </div>
                    </RevealRight>
                    <RevealLeft>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Proven Track Record</h1>
                            <p className='text-justify'>With a portfolio of satisfied clients and successful projects under our belt, we have earned a reputation for delivering exceptional results consistently.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-cards text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Transparent Pricing</h1>
                            <p className='text-justify'>We believe in transparency and integrity in all our dealings. Our pricing structure is straightforward and competitive, with no hidden fees or surprises.</p>
                        </div>
                    </RevealRight>
                </div>
            </div>
        </div>
    );
};

export default WebsiteMaintenance;