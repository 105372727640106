import React from 'react';
import Marquee from 'react-fast-marquee';
import { FaStarOfLife } from "react-icons/fa";

const ServiceMarquee = () => {
    return (
        <div className='bg-green-400 py-12 text-black'>
            <div className='mx-[5%]'>
            <Marquee speed={500}>
                <h1 className='text-[100px] font-bold flex items-center gap-6'>
                    <span className='flex items-center gap-4'><FaStarOfLife />Web Design with Figma</span>
                    <span className='flex items-center gap-4'><FaStarOfLife />Website Development</span>
                    <span className='flex items-center gap-4'><FaStarOfLife /> Website Maintenance</span>
                    <span className='flex items-center gap-4'><FaStarOfLife />Website Upgrade</span>
                    
                      </h1>

            </Marquee>
            </div>
        </div>
    );
};

export default ServiceMarquee;