import React from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import figma from '../../../assets/figma.png';
import react from '../../../assets/react.png';
import maintenance from '../../../assets/website.png';
import upgrade from '../../../assets/upgrade.png';
import TitleSlide from '../../../components/TitleSlide/TitleSlide';
import Reveal from '../../../components/Reveal/Reveal';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../components/RevealRight/RevealRight';

const WhatDo = () => {
    return (
        <div className='mt-9'>
            <Reveal>
                <h3 className='font-semibold text-green-400 text-xl text-center'>What We Do</h3>
                <div className="mx-auto w-[120px]"><TitleSlide width={`w-full`} /></div>
                <h1 className='text-[28px] font-semibold lg:w-[650px] text-center mx-auto tracking-wide text-white'>We Provide Amazing Web Development Solutions</h1>
            </Reveal>
            <div className='mt-12 grid grid-cols-1 lg:grid-cols-2 gap-9 mx-[8%] relative'>
                <div className="z-50">
                    <RevealLeft>
                        <div className='lg:flex items-center rounded gap-5 bg-gray-700  px-4 py-8'>
                            <div>
                                <img src={figma} alt="" />
                            </div>
                            <div className='lg:flex items-center gap-16'>
                                <div>
                                    <h1 className='text-white text-2xl mt-2 lg:mt-0 lg:text-3xl font-semibold tracking-wider'>Web Design with Figma</h1>
                                </div>
                                <div className='border border-gray-500 hidden lg:block rounded-full p-4 cursor-pointer text-white hover:text-black hover:bg-green-400'>
                                    <Link to='/design-with-figma'><IoIosArrowRoundForward className='font-bold text-2xl -rotate-45 flex' /></Link>
                                </div>
                            </div>
                        </div>
                    </RevealLeft>
                </div>
                <div className="z-50">
                    <RevealRight>
                        <div className='lg:flex items-center gap-5 rounded bg-gray-700  px-4 py-8 z-50'>
                            <div>
                                <img src={react} alt="" />
                            </div>
                            <div className='flex items-center gap-24'>
                                <div>
                                    <h1 className='text-white text-2xl mt-2 lg:mt-0 lg:text-3xl font-semibold tracking-wider'>Website Development</h1>
                                </div>
                                <div className='border border-gray-500 rounded-full hidden lg:block p-4 cursor-pointer text-white hover:text-black hover:bg-green-400'>
                                    <Link to='/web-development' className=''><IoIosArrowRoundForward className='font-bold text-2xl -rotate-45 flex' /></Link>
                                </div>
                            </div>
                        </div>
                    </RevealRight>
                </div>
                <div className="z-50">
                    <RevealLeft>
                        <div className='lg:flex items-center gap-5 rounded bg-gray-700  px-4 py-8'>
                            <div>
                                <img className='w-12' src={maintenance} alt="" />
                            </div>
                            <div className='flex items-center gap-24'>
                                <div>
                                    <h1 className='text-white text-2xl mt-2 lg:mt-0 lg:text-3xl font-semibold tracking-wider'>Website Maintenance</h1>
                                </div>
                                <div className='border border-gray-500 hidden lg:block rounded-full p-4 cursor-pointer text-white hover:text-black hover:bg-green-400'>
                                    <Link to='/website-maintenance'><IoIosArrowRoundForward className='font-bold text-2xl -rotate-45 flex' /></Link>
                                </div>
                            </div>
                        </div>
                    </RevealLeft>
                </div>
                <div className='z-50'>
                    <RevealRight>
                        <div className='lg:flex items-center gap-5 rounded bg-gray-700  px-4 py-8 z-50'>
                            <div>
                                <img className='w-16' src={upgrade} alt="" />
                            </div>
                            <div className='flex items-center gap-24'>
                                <div>
                                    <h1 className='text-white text-2xl lg:text-3xl mt-2 lg:mt-0 font-semibold tracking-wider'>Website Upgrade</h1>
                                </div>
                                <div className='border border-gray-500 rounded-full hidden lg:block p-4 cursor-pointer text-white hover:text-black hover:bg-green-400'>
                                    <Link to='/website-upgrade'><IoIosArrowRoundForward className='font-bold text-2xl -rotate-45 flex' /></Link>
                                </div>
                            </div>
                        </div>
                    </RevealRight>
                </div>
                <div className="animate-pulse">
                    <div className="bg-middle"></div>
                </div>
            </div>
        </div>
    );
};

export default WhatDo;