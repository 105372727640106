import React from 'react';
import Reveal from '../../../components/Reveal/Reveal';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../components/RevealRight/RevealRight';

const ServicePayment = () => {
    return (
        <div className='text-black my-9 mx-[5%] relative'>
            <Reveal>
            <h1 className='text-center text-3xl lg:text-5xl font-bold lg:w-3/4 mx-auto text-white'>We have a <span className='text-green-400'>Simple</span> and Flexible <span className='text-green-400'>Three-step</span> Payment Process</h1>
            </Reveal>
            <div className="mt-9 grid grid-cols-1 lg:grid-cols-3 gap-6">
                <RevealLeft>
                <div className="shadow-md mx-auto max-w-sm transform hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer bg-green-400 rounded-md">
                    <div className='p-7 my-auto pb-12'>
                    <h1 className='text-2xl font-semibold'>Project Initiation</h1>
                    <p className='text-xl font-light leading-relaxed text-slate-600 mt-5'>Secure your project's kickoff by making an initial payment, setting the wheels in motion for our collaborative journey towards success.</p>
                    </div>
                </div>
                </RevealLeft>
                <Reveal>
                <div className="shadow-md mx-auto max-w-sm transform hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer bg-green-400 rounded-md">
                    <div className='p-7 my-auto pb-12'>
                    <h1 className='text-2xl font-semibold'>Progress Payment</h1>
                    <p className='text-xl font-light leading-relaxed text-slate-600 mt-5'>As we make significant strides in your project's development, we request a mid-project payment, ensuring continuous momentum and progress towards your goals.</p>
                    </div>
                </div>
                </Reveal>
                <RevealRight>
                <div className="shadow-md mx-auto max-w-sm transform hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer bg-green-400 rounded-md">
                   <div className='p-7 my-auto pb-12'>
                   <h1 className='text-2xl font-semibold'>Project Completion</h1>
                    <p className='text-xl font-light leading-relaxed text-slate-600 mt-5'> Upon successful completion and delivery of your project, the final payment is due, marking the culmination of our efforts and the beginning of your digital triumph.</p>
                   </div>
                </div>
                </RevealRight>
            </div>
            <div className='animate-pulse'>
                <div className="bg-left"></div>
                </div>
        </div>
    );
};

export default ServicePayment;