import React from 'react';

const TitleSlide = ({width}) => {
    return (
        <div>
            <div class={`relative ${width}`}>
                <div className="relative h-[2px] w-full bg-slate-400 rounded-full"></div>
            
            <div class="h-2 w-2 bg-green-400 rounded-full absolute -top-[3px] left-0 input-circle"></div>
            </div>
        </div>
    );
};

export default TitleSlide;