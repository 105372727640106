import React from 'react';
import user from '../../../assets/user.png';
import diamond from '../../../assets/diamond.png';
import like from '../../../assets/like.png';
import Reveal from '../../../components/Reveal/Reveal';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../components/RevealRight/RevealRight';

const WeDo = () => {
    return (
        <div className='my-9 mx-[5%] text-white'>
            <Reveal>
            <h1 className='text-center text-2xl lg:text-4xl font-bold lg:w-3/4 mx-auto'>"We love what we do and we love helping others succeed at what they love to do."</h1>
            <p className='text-center mt-2 font-bold'>- Rakib, <span className='text-green-400'>CTO</span></p>
            </Reveal>
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-9">
                <RevealLeft>
                <div>
                    <div className='flex items-center gap-4 mb-3'>
                        <img className='w-12' src={user} alt="" />
                        <h3 className='text-2xl font-semibold'>Who We are</h3>
                    </div>
                    <hr className='w-[400px] my-5' />
                    <p className=''>We're a team of Web design and development professionals who love partnering with good people and businesses to help them achieve online success.</p>
                </div>
                </RevealLeft>
                <Reveal>
                <div>
                    <div className='flex items-center gap-4 mb-3'>
                        <img src={diamond} alt="" className="w-12" />
                        <h3 className='text-2xl font-semibold mb-3'>What We Do</h3>
                    </div>
                    <hr className='w-[400px] my-5' />
                    <p className=''>We're focused on honing our crafts and bringing everything we have to table for our clients. We create custom, functional websites focused on converting your users into customers.</p>
                </div>
                </Reveal>
                <RevealRight>
                <div>
                    <div className='flex items-center gap-4 mb-3'>
                        <img src={like} alt="" className="w-12" />
                        <h3 className='text-2xl font-semibold mb-3'>Why We Do It</h3>
                    </div>
                    <hr className='w-[400px] my-5' />
                    <p className=''>Each of us loves what we do and we feel that spirit helps translate into the quality of our work. Working with clients who love their work combines into a fun, wonderful partnership for everyone involved.</p>
                </div>
                </RevealRight>
            </div>
        </div>
    );
};

export default WeDo;