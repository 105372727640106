import React from 'react';
import ServiceBanner from './ServiceBanner/ServiceBanner';
import ServiceCards from './ServiceCards/ServiceCards';
import ServiceMarquee from './ServiceMarquee/ServiceMarquee';
import ServiceStrategy from './ServiceStrategy/ServiceStrategy';
import ServicePayment from './ServicePayment/ServicePayment';

const Services = () => {
    return (
        <div>
            <ServiceBanner/>
            <ServiceCards/>
            <ServiceMarquee/>
            <ServiceStrategy/>
            <ServicePayment/>
        </div>
    );
};

export default Services;