import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

const RevealLeft = ({children, width = "100%"}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true})
    const mainControls = useAnimation();
    const slideControls = useAnimation()

    useEffect(() => {
        if(isInView){
            mainControls.start("visible")
            slideControls.start("visible")
        }
    }, [isInView, mainControls, slideControls])
    return (
        <div ref={ref} style={{position: "relative", overflow: "hidden"}}>
        <motion.div
            variants={{
                hidden: {x: -1000},
                visible: {x: 0}
            }}
            initial="hidden"
            animate={mainControls}
            transition={{duration: 2, delay: 1}}
        >{children}</motion.div>
        {/* <motion.div
            variants={{
                hidden: {left: -500},
                visible: {left: 0}
            }}
            initial="hidden"
            animate={slideControls}
            transition={{duration: 2, delay: 1}}
        >
            
        </motion.div> */}
    </div>
    );
};

export default RevealLeft;