import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa6";
import { CiLinkedin } from "react-icons/ci";
import { IoIosArrowRoundForward } from "react-icons/io";
import logo from '../../assets/logo.svg';

const Footer = () => {
    return (
        <div className='mt-9 text-white'>
            <div className='mx-[5%]'>
            <div className='lg:flex justify-between'>
                <div>
                    <img className='w-20' src={logo} alt="" />
                </div>
                <div className='flex gap-6 mt-3 lg:mt-0'>
                    <Link target='_blank' to='https://www.facebook.com/profile.php?id=61556402805932' className='flex items-center gap-1 font-medium italic hover:text-green-400'><FaFacebookF/>Facebook</Link>
                    <Link target='_blank' to='https://www.linkedin.com/company/hbr-software-solution/' className='flex items-center gap-1 font-medium italic hover:text-green-400'><CiLinkedin/>Linkedin</Link>
                </div>
            </div>
            <hr className='mt-4' />
            <div className='lg:flex justify-between lg:divide-x'>
                <div className='hidden lg:block'>
                    <h1 className='text-5xl my-5 lg:my-0 lg:text-[85px] font-bold lg:w-2/3'>Let's Work Together</h1>
                    <div className='lg:flex items-center gap-6 my-3'>
                        <div className='border border-gray-500 p-4 rounded-xl hover:bg-green-400 hover:text-black cursor-pointer font-medium'>
                        <h1><span>project@hbrsoftwaresolution.com</span></h1>
                        </div>
                        <div className='my-3 bg-green-400 text-black p-4 rounded-xl font-medium hover:border hover:border-gray-500 hover:text-white hover:bg-black cursor-pointer'>
                            <Link target='_blank' to='https://calendly.com/bayazid-developer/collaboration-meeting-regarding-your-project' className='flex'>Book a free consultation <IoIosArrowRoundForward className='font-semibold text-2xl -rotate-45' /></Link>
                        </div>
                    </div>
                </div>
                <div className='divide-y'>
                <div className='flex  justify-center gap-6 px-8 pt-4'>
                    <div>
                    <div><Link className='font-medium flex mb-2' to='/'>Home</Link></div>
                    <div><Link className='font-medium flex mb-2' to='/services'>Service</Link></div>
                    <div><Link className='font-medium flex mb-2' to='/aboutus'>About Us</Link></div>
                    <div><Link className='font-medium flex mb-4' to='/contact'>Contact Us</Link></div>
                    </div>
                    <div>
                        <div><Link className='font-medium flex mb-2'>Terms & Service</Link></div>
                        <div><Link className='font-medium flex mb-2'>Privacy Policy</Link></div>
                        <div><Link className='font-medium flex'>Payment Guideline</Link></div>
                    </div>
                </div>
                <div className='p-3'>
                    <p className='lg:mt-14'>Copyright @2023, HBR All Rights Reserved</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Footer;