import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

const Reveal = ({children}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true})
    const mainControls = useAnimation();

    useEffect(() => {
        if(isInView){
            mainControls.start("visible")
        }
        else{
            mainControls.stop()
        }
    }, [isInView, mainControls])
    return (
        <div ref={ref} style={{position: "relative",  overflow: "hidden"}}>
            <motion.div
                variants={{
                    hidden: {opacity: 0, y: 75},
                    visible: {opacity: 1, y: 0}
                }}
                initial="hidden"
                animate={mainControls}
                transition={{duration: 2, delay: 1}}
            >{children}</motion.div>
            
        </div>
    );
};

export default Reveal;