import React from 'react';
import heroRight from '../../../assets/hero-right.png'
import '../../../App.css'
import { CiLinkedin } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import bannerImg from '../../../assets/hbr-banner.jpg';
import Reveal from '../../../components/Reveal/Reveal';

const Banner = () => {
    return (
        <div className='overflow-hidden'>
            <div className='relative'>
                <div className='flex justify-between ml-[3%]'>
                    <div>
                        <Reveal>
                        <h1 className='text-[80px] lg:text-[150px] -mb-[40px] lg:-mb-[85px] uppercase font-bold text-white'>website</h1>
                        <h1 className='text-[80px] lg:text-[150px] -mb-[40px] lg:-mb-[85px] uppercase font-bold title-color'>develop</h1>
                        <h1 className='text-[80px] lg:text-[150px] uppercase font-bold text-white tracking-wide'><i>agency</i></h1>
                        </Reveal>
                    </div>
                    <div className='-ml-10 z-10 hidden lg:block'>
                        <Reveal><img src={heroRight} alt="" className='-ml-10' /></Reveal>
                    </div>

                </div>

            </div>
            <div className='text-white flex items-center ml-[2%]'>
                <div className='lg:-mt-28'>
                    <img src={bannerImg} alt="" />
                </div>
                <div className='hidden lg:block'>
                <div className='flex -rotate-90 gap-x-10 -ml-1'>
                    <Link target='_blank' to='https://www.linkedin.com/company/hbr-software-solution/' className='flex items-center gap-1 hover:text-green-400'><CiLinkedin /><span className='hover:underline'>Linkedin</span></Link>
                    <Link target='_blank' to='https://www.facebook.com/profile.php?id=61556402805932' className='flex items-center gap-1 hover:text-green-400'><FaFacebookF /><span className='hover:underline'>Facebook</span></Link>
                    <Link target='_blank' to='https://calendly.com/bayazid-developer/collaboration-meeting-regarding-your-project' className='text-sm font-semibold italic flex items-center w-full gap-x-2 text-green-400 underline'>Quote</Link>
                </div>
                </div>
            </div>
            <div className='animate-pulse'>

            <div className="bg">
                
            </div>
            </div>
        </div>
    );
};

export default Banner;