import React from 'react';
import Reveal from '../../../components/Reveal/Reveal';

const AboutBanner = () => {
    return (
        <div className='my-9 mx-[5%]'>
            <Reveal>
            <h1 className='text-4xl lg:text-9xl font-bold text-white tracking-wide'>We're Digital Web Development Agency</h1>
            </Reveal>
        </div>
    );
};

export default AboutBanner;