import React from 'react';
import Banner from './Banner/Banner';
import AboutCompany from './AboutCompany/AboutCompany';
import TextMarquee from './TextMarquee/TextMarquee';
import WhatDo from './WhatDo/WhatDo';
import WhyUs from './WhyUs/WhyUs';
import SolutionMarquee from './SolutionMarquee/SolutionMarquee';
import Offer from './Offer/Offer';
import Reveal from '../../components/Reveal/Reveal';

const Home = () => {
    return (
        <div>
            <Banner/>
            <Reveal><AboutCompany/></Reveal>
            <TextMarquee/>
            <WhatDo/>
            <Reveal><WhyUs/></Reveal>
            <SolutionMarquee/>
            <Offer/>
        </div>
    );
};

export default Home;