import React from 'react';
import Marquee from 'react-fast-marquee';

const SolutionMarquee = () => {
    return (
        <div className='mx-[5%]'>
            <Marquee speed={150}>
            <h1 className='text-white text-[130px] lg:text-[200px] font-bold uppercase mr-14'>digital <span className='title-color'>solution</span></h1>
            </Marquee>
        </div>
    );
};

export default SolutionMarquee;