import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';
import RevealLeft from '../../components/RevealLeft/RevealLeft';
import RevealRight from '../../components/RevealRight/RevealRight';
import Reveal from '../../components/Reveal/Reveal';
import Faq from '../About/Faq/Faq';

const Contact = () => {
    
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      
      const f = e.target;
  
      emailjs
        .sendForm('service_yige1u5', 'template_q0p4q1p', form.current, {
          publicKey: 'Y7DMF97K3_3YlbBpX',
        })
        .then(
          () => {
            toast.success("Message sent successfully");
            f.reset();
          },
          (error) => {
            toast.error(error.text);
          },
        );
    };
    return (
        <div>
            <div className='grid grid-cols-1 lg:grid-cols-2 items-center mx-[5%] my-9'>
                <RevealLeft>
                <div>
                    <Reveal><h3 className='font-semibold text-green-400 text-xl'>Contact Us</h3></Reveal>
                    <Reveal>
                    <h1 className='text-6xl leading-tight font-bold text-white my-3'>Let's level up your business, together</h1>
                    </Reveal>
                    <Reveal>
                    <p className='lg:w-3/4'>You can reach us anytime via <span className='text-green-400'>project@hbrsoftwaresolution.com</span>, or simply fill out the contact form, we usually respond within 2 business days.</p>
                    </Reveal>
                </div>
                </RevealLeft>
                <RevealRight>
                <div>
                    <h3 className='font-semibold text-green-400 text-xl'>Get Free Quote</h3>
                    <h1 className='text-white font-bold text-4xl mt-2 mb-5'>Drop us a Message</h1>
                    <form  ref={form} onSubmit={sendEmail}>
                        <div>
                            <p className="mb-1">Name</p>
                            <input name='from_name' type="text" placeholder="Jhon Smith" required className="input input-bordered w-full max-w-xs" />
                        </div>
                        <div className='my-3'>
                            <p className="mb-1">Email</p>
                            <input  name='from_email' type="email" placeholder="example@gmail.com" required className="input input-bordered w-full max-w-xs" />
                        </div>
                        <div>
                            <p className='mb-1'>Select type of service</p>
                            <select name='message' className="select select-bordered w-full max-w-xs" required>
                                <option selected>Web Design with Figma</option>
                                <option>Website Development</option>
                                <option>Website Maintenance</option>
                                <option>Website Upgrade</option>
                            </select>
                        </div>
                        <div className='mt-4'>
                            <input className='bg-green-400 text-white rounded font-semibold p-3 cursor-pointer' type="submit" value="Send Message" />
                        </div>
                    </form>
                </div>
                </RevealRight>
            </div>

            <Faq/>
        </div>
    );
};

export default Contact;