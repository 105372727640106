import React from 'react';
import TitleSlide from '../../../components/TitleSlide/TitleSlide';
import web from '../../../assets/web-solution.png';
import interfaces from '../../../assets/best-interface.png';
import trust from '../../../assets/trust.png';
import one from '../../../assets/about-06.jpg';
import two from '../../../assets/about-07.jpg';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../components/RevealRight/RevealRight';
import Reveal from '../../../components/Reveal/Reveal';

const AboutUs = () => {
    return (
        <div className='mt-9 mb-20 mx-[5%]'>
            <div className='grid grid-cols-1 lg:grid-cols-2'>
                <RevealLeft>
                    <div>
                        <div className='relative'>
                            <img className='rounded-2xl' src={one} alt="" />
                            <img className='ml-56 -mt-64 rounded-2xl' src={two} alt="" />
                        </div>
                        <div className=' w-[380px] absolute -mt-24 p-4 rounded-md bg-white ceo-box'>
                            <h3 className='text-black'>"If you want custom & eye-catching website that makes an better effect - HBR got you covered." - <span className='font-bold italic'>Bayazid,</span> <span className='text-green-400 font-bold'>CEO</span></h3>
                        </div>
                    </div>
                </RevealLeft>
                <RevealRight>
                    <div>
                        <h3 className='font-semibold text-green-400 text-xl'>About Us</h3>
                        <div className='w-[90px]'><TitleSlide width={`w-full`} /></div>
                        <h1 className='text-2xl lg:text-4xl font-bold text-white my-5'>We offer the best <span className='text-green-400'>Web Development</span> service in the town.</h1>
                        <p>We focus at every stage on effective communication and collaboration between the client and ensuring that the final design meets the client's objectives and expectations.</p>
                        <div className="mt-8">
                            <div className='grid grid-cols-2 items-center'>
                                <div>
                                    <Reveal>
                                        <div className='flex gap-4'>
                                            <div>
                                                <img src={web} alt="" />
                                            </div>
                                            <div>
                                                <h3 className='text-3xl font-bold text-white mb-3'>Web Solutions</h3>
                                                <p>Experience the power of a tailored web solution designed to meet your unique business needs.</p>
                                            </div>
                                        </div>
                                    </Reveal>
                                    <Reveal>
                                        <div className='flex gap-4 mt-5'>
                                            <div>
                                                <img src={interfaces} alt="" />
                                            </div>
                                            <div>
                                                <h3 className='text-3xl font-bold text-white mb-3'>Best Interface</h3>
                                                <p>Elevate your online presence with our web development agency's expertise in crafting the best interface designs.</p>
                                            </div>
                                        </div>
                                    </Reveal>
                                </div>
                                <Reveal>
                                    <div className='ml-20 bg-gray-900 p-5 w-[200px] border-b-2 border-green-400'>
                                        <img className='bg-black p-4 rounded-full w-20 mx-auto' src={trust} alt="" />
                                        <p className='text-white font-semibold text-center mt-2'>Trusted By Our Clients</p>
                                    </div>
                                </Reveal>
                            </div>
                        </div>
                    </div>
                </RevealRight>
            </div>
        </div>
    );
};

export default AboutUs;