import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundForward } from "react-icons/io";
import circle from '../../../assets/circle-text.svg';
import TitleSlide from '../../../components/TitleSlide/TitleSlide';

const AboutCompany = () => {
    return (
        <div className='mt-14 mb-9 mx-[8%]'>
            <div className='grid grid-cols-1 items-center lg:grid-cols-2 text-white'>
                <div>
                    <img className='animate-[wiggle_7s_ease-in-out_infinite]' src={circle} alt="" />
                </div>
                <div>
                    <h3 className='font-semibold text-green-400 text-xl'>About Company</h3>
                    <div className='w-[150px]'><TitleSlide width={`w-full`}/></div>
                    <h1 className='mt-9 mb-5 font-bold text-2xl lg:text-[40px] tracking-wide'>Our Main Goal To Satisficed</h1>
                    <h1 className='font-bold text-2xl lg:text-[40px] tracking-wide'> Local & Global Clients</h1>
                    <div className='ml-4 lg:ml-14'>
                    <p className='my-20 lg:text-justify'>At HBR Software Solutions, our mission is to empower businesses with cutting-edge technological solutions that elevate their presence in the digital landscape. We strive to exceed expectations, delivering tailor-made software products that drive growth, efficiency, and success for our clients.</p>
                    <Link to='/aboutus' className='underline flex items-center gap-1 hover:text-green-400 font-semibold'>Read More <IoIosArrowRoundForward className='font-semibold text-2xl -rotate-45' /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutCompany;