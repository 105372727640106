import React from 'react';
import AboutBanner from './AboutBanner/AboutBanner';
import Establishment from './Establishment/Establishment';
import WeDo from './WeDo/WeDo';
import AboutUs from './AboutUs/AboutUs';
import Faq from './Faq/Faq';

const About = () => {
    return (
        <div>
            <AboutBanner/>
            <Establishment/>
            <AboutUs/>
            <WeDo/>
            <Faq/>
        </div>
    );
};

export default About;