import React from 'react';
import leaf from '../../../../assets/leaf.png';
import WhyUs from '../../../Home/WhyUs/WhyUs';
import RevealLeft from '../../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../../components/RevealRight/RevealRight';
import Reveal from '../../../../components/Reveal/Reveal';

const WebDevelopment = () => {
    return (
        <div className="mx-[5%]">
            <div className='flex gap-2 my-20'>
                <RevealLeft><h1 className='text-3xl lg:text-6xl font-bold text-white'>Website Development</h1></RevealLeft>
                <RevealRight><img className='w-16 -mt-10' src={leaf} alt="" /></RevealRight>
            </div>
            <div className='my-20'>
                <WhyUs/>
            </div>
            <div className="my-20">
                <RevealLeft><h3 className='font-semibold text-green-400 text-xl'>How The Web Development Works</h3></RevealLeft>
                <div className='mt-8 grid grid-cols-1 lg:grid-cols-3 gap-5'>
                    <RevealLeft>
                    <div className='bg-[#ffffff1a] p-7 rounded-xl'>
                        <h3 className='text-3xl font-semibold text-gray-500'>01</h3>
                        <h1 className='text-3xl font-bold my-3'>Front-End Development</h1>
                        <p className='mt-5'>We understand the importance of captivating user interfaces that not only engage but also elevate user experience to new heights. Leveraging the power of <span className='text-green-400 font-medium'>React.js</span>, <span className='text-green-400 font-medium'>JavaScript</span>, and <span className='text-green-400 font-medium'>Tailwind CSS</span>, we design and develop stunning front-end solutions that captivate your audience from the moment they land on your website. Whether it's creating dynamic user interfaces, implementing smooth animations, or ensuring seamless navigation across devices, our front-end development expertise sets your brand apart in the digital landscape.</p>
                    </div>
                    </RevealLeft>
                    <Reveal>
                    <div className='bg-[#ffffff1a] p-7 rounded-xl'>
                        <h3 className='text-3xl font-semibold text-gray-500'>02</h3>
                        <h1 className='text-3xl font-bold my-3'>Back-End Development</h1>
                        <p className='mt-5'>Behind every exceptional website lies a robust and reliable back-end infrastructure. With <span className='text-green-400'>Node.js</span> and <span className='text-green-400'>Express.js</span>, we architect scalable, efficient, and secure back-end systems that power your digital presence. Our back-end development team specializes in crafting APIs, handling data management, and orchestrating complex business logic to ensure seamless functionality and optimal performance. From user authentication to database management, we handle every aspect of back-end development with precision and expertise.</p>
                    </div>
                    </Reveal>
                    <RevealRight>
                    <div className='bg-[#ffffff1a] p-7 rounded-xl'>
                        <h3 className='text-3xl font-semibold text-gray-500'>03</h3>
                        <h1 className='text-3xl font-bold my-3'>Database Management</h1>
                        <p className='mt-5'>A solid foundation is crucial for the success of any digital endeavor. That's why we entrust <span className='text-green-400'>MongoDB</span>, a leading <span className='text-green-400'>NoSQL</span> database, to store and manage your data effectively. Whether it's handling massive volumes of information or facilitating real-time updates, MongoDB empowers us to build flexible, scalable, and high-performance database solutions tailored to your specific requirements. With MongoDB, your data is in safe hands, ensuring reliability, scalability, and agility for your digital projects.</p>
                    </div>
                    </RevealRight>
                </div>
            </div>

            {/* FAQ  */}
            <div className='my-20 grid grid-cols-1 lg:grid-cols-2'>
                <RevealLeft>
                <div>
                    <h1 className='text-3xl lg:text-5xl font-semibold mb-7 text-white'>Our High-Quality Working Processes</h1>
                    <p className='lg:w-3/4 my-3'>We focus at every stage on effective communication and collaboration between the client and ensuring that the final design meets the client's objectives and expectations.</p>
                    <p className='my-3 lg:w-3/4'>It is important to note that these are simplified steps, and the actual work process may vary depending on the complexity of the project.</p>
                    <p className='lg:w-3/4'>It is important to note that these are simplified steps, and the actual work process may vary depending on the complexity of the project.</p>
                </div>
                </RevealLeft>
                <RevealRight>
                <div>
                    <div className="collapse collapse-arrow border-2 border-green-400">
                        <input type="radio" name="my-accordion-2" defaultChecked />
                        <div className="collapse-title text-xl font-semibold">
                            01. Project Idea
                        </div>
                        <div className="collapse-content">
                            <p>The process starts with a detailed discussion with the client to understand their idea & goals.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow border-2 border-green-400 my-3">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-xl font-bold">
                            0.2 Brainstorming
                        </div>
                        <div className="collapse-content">
                            <p className='text-justify'>Unlock the potential of your project through collaborative brainstorming sessions with our team of creative minds. We harness collective expertise to generate innovative ideas and solutions tailored to your unique requirements. From concept exploration to feature prioritization, our brainstorming sessions lay the foundation for a successful and impactful software solution.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow border-2 border-green-400 my-3">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-xl font-bold">
                            0.3 wireframe
                        </div>
                        <div className="collapse-content">
                            <p className='text-justify'>Visualize the blueprint of your software solution with precision and clarity through our wireframing process. We craft detailed wireframes that outline the layout, structure, and functionality of your application, providing a clear roadmap for development. With user experience at the forefront, our wireframes ensure intuitive navigation and seamless interaction, setting the stage for a superior end product.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-arrow border-2 border-green-400">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-xl font-bold">
                            0.4 Launch
                        </div>
                        <div className="collapse-content">
                            <p className='text-justify'>Celebrate the culmination of your vision as we prepare to launch your software solution into the digital realm. Our launch process is meticulously planned and executed to ensure a smooth transition from development to deployment. From final testing and quality assurance to deployment and post-launch support, we stand by your side every step of the way, ensuring a successful and impactful launch that captivates your audience.</p>
                        </div>
                    </div>
                </div>
                </RevealRight>
            </div>
        </div>
    );
};

export default WebDevelopment;