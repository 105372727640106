import React from 'react';
import Reveal from '../../../components/Reveal/Reveal';

const StrategyCard = ({ item }) => {
    const { steps, title, des } = item;
    return (
        <div className='even:-mt-20 z-50'>
            <div className='hover:shadow-slate-300 hover:shadow-sm rounded-full'>
                <div className='rounded-full border border-[#ffffff1a] h-64 w-64 p-5 -ml-5 -mt-5 bg-[#ffffff1a] strategy'>
                    <div className='mt-5'>
                        <Reveal>
                        <div>
                        <h4 className="text-center text-green-400 text-lg font-semibold">Steps {steps}</h4>
                        <h3 className="text-2xl font-bold text-center my-5">{title}</h3>
                        <hr className='text-slate-500' />
                        <p className="text-center my-5">{des}</p>
                        </div>
                        </Reveal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StrategyCard;