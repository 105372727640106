import React from 'react';
import { HiCheck } from "react-icons/hi";
import TitleSlide from '../../../components/TitleSlide/TitleSlide';
import Reveal from '../../../components/Reveal/Reveal';
import RevealRight from '../../../components/RevealRight/RevealRight';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';

const WhyUs = () => {
    return (
        <div className='mt-16 mx-[5%]'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 items-center'>
                <div>
                    <RevealLeft><img src='https://i.ibb.co/RQ3bGjY/why-choose-left.jpg' alt="" /></RevealLeft>
                </div>
                <div>
                    <RevealRight>
                    <h3 className='font-semibold text-green-400 text-xl'>Why Choose Us</h3>
                    <div className="w-[150px]"><TitleSlide width={`w-full`}/></div>
                    <h1 className='text-3xl lg:text-5xl font-semibold tracking-wide leading-tight my-4 text-white lg:w-[550px]'>We provide Services in One Place</h1>
                    </RevealRight>
                    <div className="mt-6">
                        <Reveal>
                        <div>
                            <div className='flex items-center gap-5'>
                                <div className='bg-green-400  rounded-full'><HiCheck className='text-2xl font-bold text-white' /></div>
                                <div>
                                    <h2 className="text-2xl font-bold text-white">Best Quality</h2>
                                </div>
                            </div>
                            <hr className='lg:w-72 my-3' />
                            <div>
                                <p className='lg:w-3/4 mt-3'>Our commitment to excellence in web development is evident in our meticulous attention to detail in crafting each solution.</p>
                            </div>
                        </div>
                        </Reveal>
                        <Reveal>
                        <div className='my-8'>
                            <div className='flex items-center gap-5'>
                                <div className='bg-green-400  rounded-full'><HiCheck className='text-2xl font-bold text-white' /></div>
                                <div>
                                    <h2 className="text-2xl font-bold text-white">Innovation</h2>
                                </div>
                            </div>
                            <hr className='lg:w-72 my-3' />
                            <div>
                                <p className='lg:w-3/4 mt-3'>Our passion for innovation drives us to push boundaries and pioneer new solutions in the digital landscape, fueled by curiosity and forward thinking.</p>
                            </div>
                        </div>
                        </Reveal>
                        <Reveal>
                        <div>
                            <div className='flex items-center gap-5'>
                                <div className='bg-green-400  rounded-full'><HiCheck className='text-2xl font-bold text-white' /></div>
                                <div>
                                    <h2 className="text-2xl font-bold text-white">Always iterate</h2>
                                </div>
                            </div>
                            <hr className='lg:w-72 my-3' />
                            <div>
                                <p className='lg:w-3/4 mt-3'>Our DNA is rooted in continuous improvement, valuing feedback, adaptability, and iteration to ensure our products adapt to the ever-changing needs of our clients and the industry.</p>
                            </div>
                        </div>
                        </Reveal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyUs;