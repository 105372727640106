import React from 'react';
import leaf from '../../../assets/leaf.png';
import WhyUs from '../../Home/WhyUs/WhyUs';
import Marquee from 'react-fast-marquee';
import RevealLeft from '../../../components/RevealLeft/RevealLeft';
import RevealRight from '../../../components/RevealRight/RevealRight';

const DesignWithFigma = () => {
    return (
        <div className='mx-[5%]'>
            <div className='flex gap-2 my-20'>
                <RevealLeft><h1 className='text-3xl lg:text-6xl font-bold text-white'>Web Design with Figma</h1></RevealLeft>
                <RevealRight><img className='w-16 -mt-10' src={leaf} alt="" /></RevealRight>
            </div>
            <div className='my-20'>
                <WhyUs />
            </div>
            <div className='my-20'>
                <RevealLeft><h1 className='text-3xl lg:text-5xl font-bold text-white'>Why Choose Web Design with Figma</h1></RevealLeft>
                <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <RevealLeft>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Innovative Design Process</h1>
                            <p className='text-justify'>Figma is not just another design tool. It's a collaborative platform that allows us to streamline the design process from concept to execution. With Figma, our team can work seamlessly together, ensuring that every pixel is perfect and every element is strategically placed to enhance user experience.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Responsive Design</h1>
                            <p className='text-justify'>In today's digital landscape, it's crucial to have a website that looks and performs flawlessly across all devices. With Figma, we can create responsive designs that adapt effortlessly to various screen sizes, ensuring that your website looks stunning whether it's viewed on a desktop, tablet, or smartphone.</p>
                        </div>
                    </RevealRight>
                    <RevealLeft>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Interactive Prototyping</h1>
                            <p className='text-justify'>Figma's powerful prototyping features allow us to bring your website to life before a single line of code is written. Through interactive prototypes, you can visualize how your website will look and function, providing valuable feedback that ensures we're aligned every step of the way.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-card text-black px-4 py-6 rounded'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Efficient Collaboration</h1>
                            <p className='text-justify'>With Figma, collaboration is at the core of our design process. Whether you're across the globe or just down the street, our team can work collaboratively in real-time, making revisions and iterating quickly to bring your vision to life.</p>
                        </div>
                    </RevealRight>
                </div>
            </div>
            <div className="my-20">
                <Marquee speed={350}>
                    <h1 className='uppercase text-white text-[130px] lg:text-[230px] font-bold'>Web <span className='title-color'>Design</span> with <span className='title-color'>Figma</span></h1>
                </Marquee>
            </div>
            <div className="my-20">
                <RevealRight>
                    <h1 className='text-3xl lg:text-5xl font-bold text-white text-end'>Our Approach to Web Design with Figma</h1>
                </RevealRight>
                <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <RevealLeft>
                        <div className='service-details-cards px-4 py-6 rounded text-black'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Discovery Phase</h1>
                            <p className='text-justify'>We begin by gaining a deep understanding of your brand, your target audience, and your goals for the website. Through thorough research and discovery sessions, we lay the foundation for a design that not only looks beautiful but also resonates with your audience and drives results.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-cards px-4 py-6 rounded text-black'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Design Iteration</h1>
                            <p className='text-justify'>Armed with insights from the discovery phase, our team dives into the design process using Figma's robust toolkit. We create wireframes and mockups that visualize the structure and layout of your website, allowing for iterative feedback and refinement until we achieve a design that exceeds your expectations.</p>
                        </div>
                    </RevealRight>
                    <RevealLeft>
                        <div className='service-details-cards px-4 py-6 rounded text-black'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Prototyping and Testing</h1>
                            <p className='text-justify'>Once the initial designs are approved, we leverage Figma's prototyping capabilities to create interactive prototypes that simulate the user experience. Through user testing and feedback, we fine-tune every interaction and animation to ensure a seamless and intuitive browsing experience.</p>
                        </div>
                    </RevealLeft>
                    <RevealRight>
                        <div className='service-details-cards px-4 py-6 rounded text-black'>
                            <h1 className='text-2xl lg:text-3xl font-bold mb-3'>Development Handoff</h1>
                            <p className='text-justify'>Finally, we deliver the finalized designs to our development team, providing comprehensive documentation and assets to ensure a smooth transition from design to development. Our developers work diligently to bring your vision to life, leveraging the latest technologies and best practices to create a website that is not only visually stunning but also high-performing and user-friendly.</p>
                        </div>
                    </RevealRight>
                </div>
            </div>
        </div>
    );
};

export default DesignWithFigma;