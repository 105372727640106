import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';

const menuItem = (
    <React.Fragment>
        <li>
            <Link to="/">Home</Link>
        </li>
        <li>
            <Link to='/services'>Services</Link>
        </li>
        <li>
            <Link to='/aboutus'>About Us</Link>
        </li>
    </React.Fragment>
)

const Navbar = () => {
    return (
        <div>
            <div className='bg-black text-white'>
            <div className="navbar">
                <div className="navbar-start">
                    <div className="dropdown">
                        <div tabIndex={0} role="button" className="btn lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </div>
                        <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 text-black font-semibold">
                            {menuItem}
                        </ul>
                    </div>
                    <Link to='/' className='flex items-center'>
                        <img className='w-20 hidden lg:block' src={logo} alt="" /> 
                        {/* <span className="text-2xl text-green-400 font-bold">HBR</span> */}
                    </Link>
                </div>
                <div className="navbar-center hidden lg:flex">
                    <ul className="flex items-center gap-6 px-1 text-lg font-semibold">
                        {menuItem}
                    </ul>
                </div>
                <div className="navbar-end gap-3">
               <div className="border border-green-400 text-sm font-semibold px-3 py-2 hover:bg-green-400 rounded hover:text-black z-50">
               <Link target='_blank' to='https://calendly.com/bayazid-developer/collaboration-meeting-regarding-your-project'>Become a Client</Link>
               </div>
               <div className='hidden lg:block'>
                <Link className='bg-green-400 px-3 py-2 border border-green-400  text-sm font-semibold text-black rounded hover:bg-black hover:text-white hover:border-green-400 cursor-pointer z-50' to='/contact'>Contact</Link>
               </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Navbar;