import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main/Main";
import Home from "../Pages/Home/Home";
import Services from "../Pages/Services/Services";
import Contact from "../Pages/Contact/Contact";
import About from "../Pages/About/About";
import WebDevelopment from "../Pages/Services/ServiceCards/ServicesDetails/WebDevelopment";
import DesignWithFigma from "../Pages/Services/ServiceCards/DesignWithFigma";
import WebsiteMaintenance from "../Pages/Services/ServiceCards/WebsiteMaintenance";
import WebsiteUpgrade from "../Pages/Services/ServiceCards/WebsiteUpgrade";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Main/>,
        children: [
            {
                path: '/',
                element: <Home/>
            },
            {
                path:'/services',
                element: <Services/>
            },
            {
                path: '/aboutus',
                element: <About/>
            },
            {
                path: '/contact',
                element: <Contact/>
            },
            {
                path: '/web-development',
                element: <WebDevelopment/>
            },
            {
                path: '/design-with-figma',
                element: <DesignWithFigma/>
            },
            {
                path: '/website-maintenance',
                element: <WebsiteMaintenance/>
            },
            {
                path: '/website-upgrade',
                element: <WebsiteUpgrade/>
            }
        ]
    }
]);

export default router;