import React from 'react';
import Marquee from 'react-fast-marquee';

const TextMarquee = () => {
    return (
        <div>
            <Marquee speed={350}>
                <h1 className='text-white text-[150px] lg:text-[230px] font-bold uppercase mr-14'>web <span className='title-color'>development</span> agency<span className='text-green-400'>.</span></h1>
            </Marquee>
        </div>
    );
};

export default TextMarquee;